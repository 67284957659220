.app__skills {
   flex: 1;
   width: 100%;
   flex-direction: column;
 }
 
 .app__skills-container {
   width: 90%;
   margin-top: 3rem;
   margin-bottom: 3rem;
   display: flex;
   flex-direction: row;
 
   @media screen and (max-width: 900px) {
     width: 100%;
     flex-direction: column;
   }
 }
 
 .app__skills-list {
   
   max-width: 20em;
   flex: 1;
   display: flex;
   flex-wrap: wrap;
   justify-content: flex-start;
   
 
   margin-right: 5rem;
 
   @media screen and (max-width: 900px) {
     margin-right: 0;
     justify-content: center;
     align-items: center;
   }
 }
 
 .app__skills-item {
   flex-direction: column;
   text-align: center;
   flex: 40%;
   margin: 1rem;
 
   transition: all 0.3s ease-in-out;
 
   div {
     width: 90px;
     height: 90px;
     border-radius: 50%;
     background-color: #fef4f5;
 
     img {
       width: 50%;
       height: 50%;
     }
 
     &:hover {
       box-shadow: 0 0 25px #fef4f5;
     }
 
     @media screen and (min-width: 2000px) {
       width: 150px;
       height: 150px;
     }
 
     @media screen and (max-width: 450px) {
       width: 70px;
       height: 70px;
     }
   }
 
   p {
     font-weight: 500;
     margin-top: 0.5rem;
   }
 
   @media screen and (min-width: 2000px) {
     margin: 1rem 2rem;
 
     p {
       margin-top: 1rem;
     }
   }
 }
 
 .image {
  width: 280px;
  height: 280px;
  margin-top: 20px;
  margin-bottom: 20px;

 }

 .app__skills-exp {
   flex: 1;
   display: flex;
   justify-content: flex-start;
   align-items: flex-start;
   flex-direction: column;
 
   @media screen and (max-width: 900px) {
     margin-top: 2rem;
   }
 }
 
 .app__skills-exp-item {
   width: 100%;
   display: flex;
   flex-direction: row;
   justify-content: flex-start;
   align-items: flex-start;
   margin: 1rem 0;
 }
 
 .app__skills-exp-works {
   flex: 1;
 
   .app__skills-exp-work {
     display: flex;
     flex-direction: column;
     justify-content: flex-start;
     align-items: flex-start;
     margin-bottom: 1rem;
     cursor: pointer;
 
     h4 {
       font-weight: 500;
     }
 
     p {
       font-weight: 400;
       color: var(--gray-color);
       margin-top: 5px;
     }
   }
 }

 .name {
  display: flex;
  flex-direction: row;
  gap: 15px;

  img {
    width: 20px;
    height: 20px;
  }

 }
 
 .app__skills-exp-year {
   margin-right: 3rem;
 
   p {
     font-weight: 800;
     color: var(--secondary-color);
   }
 
   @media screen and (max-width: 450px) {
     margin-right: 1rem;
   }
 }
 
 .skills-tooltip {
   max-width: 300px !important;
   background-color: var(--white-color) !important;
   box-shadow: 0 0 25px rgba(0, 0, 0, 0.1) !important;
   border-radius: 5px !important;
   padding: 1rem !important;
   color: var(--gray-color) !important;
   text-align: center !important;
   line-height: 1.5 !important;
   opacity: 1 !important;
 
   @media screen and (min-width: 2000px) {
     font-size: 1.75rem !important;
     max-width: 500px !important;
     line-height: 2 !important;
   }
 }