#home {
   position: relative;
   background: url("../../assets/bgIMG.png");
   background-size: cover;
   background-position: center;
   background-repeat: repeat;
 
   // disable the default padding styles
   .app__wrapper {
     padding: 0;
 
     .copyright {
       display: none;
     }
   }
 }
 


 .app__header {
   flex: 1;
   width: 100%;
   height: 100%;
   flex-direction: row;
    max-width: 1200px;
   padding: 8rem 2rem 0rem;
   padding-bottom: 3rem;

 
   @media screen and (min-width: 2000px) {
     padding-top: 10rem;
   }
 
   @media screen and (max-width: 1000px) {
     flex-direction: column;
   }
 
   @media screen and (max-width: 450px) {
     padding: 7rem 1rem 2rem;
   }
 }
 
 .app__header-info {
   flex: 0.65;
   display: flex;
   flex-direction: column;
   justify-content: flex-start;
   align-items: flex-start;
   height: 100%;
   margin: 0 2rem;
   max-width: 300px;
  
 
   @media screen and (max-width: 1200px) {
     width: 100%;
     margin-right: 0rem;
   }
 }
 
 .app__header-badge {
   width: 100%;
   display: flex;
   justify-content: flex-end;
   align-items: flex-end;
   flex-direction: column;
   max-width: 300px;
    
   .badge-cmp,
   .tag-cmp {
     padding: 1.5rem 1rem;
     background: var(--white-color);
     text-align: start;
     border-radius: 15px;
     flex-direction: row;
     width: auto;
     box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);

   }
 
   .tag-cmp {
     flex-direction: column;
     margin-top: 2rem;
 
     p {
       width: 100%;
      //  text-transform: uppercase;
       text-align: right;
     }

     
   }
 
   span {
     font-size: 2.5rem;
 
     @media screen and (min-width: 2000px) {
       font-size: 5rem;
     }
   }
 
   @media screen and (max-width: 1200px) {
     justify-content: flex-start;
     align-items: flex-start;
   }


 }
 
 .app__header-img {
  padding: 20px;
  max-width: 500px;
  min-width: 250px;
 }



 .app__header-circles {
   flex: 0.75;
   display: flex;
   flex-direction: column;
   justify-content: space-evenly;
   align-items: flex-start;
   height: 100%;
   margin-left: 1rem;
   max-width: 300px;
 
   div {
     width: 100px;
     height: 100px;
     border-radius: 50%;
 
     background: var(--white-color);
     box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
 
     img {
       width: 60%;
       height: 60%;
     }

   }
 
   // circles with different height & width
 
   div:nth-child(1) {
     max-width: 100px;
     max-height: 100px;
   }
 
  //  div:nth-child(2) {
  //    margin: 1rem;
  //    min-width: 120px;
  //    min-height: 120px;
  //    max-width: 250px;
  //    max-height: 250px;
  //    img {
  //     width: 85%;
  //     height: 85%;
  //   }
  //  }
 
   div:nth-child(2) {
     max-width: 90px;
     max-height: 90px;
     img {
      width: 90%;
      height: 75%;
    }
   }
 
   div:nth-child(3) {
     max-width: 90px;
     max-height: 90px;
   }
 
   div:nth-child(4) {
    margin: 1rem;
     max-width: 80px;
     max-height: 80px;
   }
 
   @media screen and (min-width: 2000px) {
     div:nth-child(2) {
       width: 400px;
       height: 400px;
     }
 
     div:nth-child(3) {
       width: 170px;
       height: 170px;
     }
 
     div:nth-child(1) {
       width: 200px;
       height: 200px;
     }
   }
 
   @media screen and (max-width: 1200px) {
     width: 100%;
     flex-direction: row;
     flex-wrap: wrap;
     margin-left: 0;
 
     div {
       margin: 1rem;
     }
   }
 }
 
 .app__header-img {
   flex: 1;
   height: 100%;
   overflow: hidden;
   display: flex;
   align-items:center;
   position: relative;
  //  max-width: 400px;
 
   img {
     border-radius: 15%;
     width: 100%;
     object-fit: contain;
     z-index: 1;
   }
   
  //  .circle_animation{
  //   overflow: hidden;
  //  }
   .overlay_circle {
     position: absolute;
     left: 0;
     right: 0;
     bottom: 0;
     z-index: 999;
     width: 100%;
     height: 100%;
     

   }
 
   @media screen and (max-width: 1200px) {
     margin: 2rem 0;
   }
 }